import { type ClassValue, clsx } from 'clsx'
import { FieldErrors } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function exhaustiveGuard(val: never, message?: string): never {
  throw new Error(message ?? `Unhandled case. Value should be never but was ${val}`)
}

export function raise<T>(message: string): T {
  throw new Error(message)
}

export type IterableElement<IterableType> = IterableType extends Iterable<infer ElementType> ? ElementType : never

export function parseRootErrorMessage(errors: FieldErrors) {
  return Object.keys(errors.root || []).reduce((acc: string | undefined, rootErrorFieldName) => {
    const errorMessage = errors.root![rootErrorFieldName].message
    if (acc === undefined) {
      acc = errorMessage
    } else {
      acc = `${acc}. ${errorMessage}.`
    }

    return acc
  }, undefined)
}
